/* html {
  height: 100vh;
} */
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
}
@font-face {
  font-family: "Noto-Sans-Bangla";
  src: local("Noto-Sans-Bangla"),
    url("./assets/Noto-Sans-Bengali-Regular.ttf") format("truetype");
  font-weight: normal;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}
h1,
marquee {
  font-family: "Noto-Sans-Bangla !important";
}
